.navbar {
   position: relative;
    height: 7em;
}

.navbar button{
    float: right;
    margin-top: 1em;
    margin-right: 1em;
    width: 2em;
}

.navbar img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
