:root {
  --main-color: #70904A;
  --danger-color: #CF0000;
  --main-color-hover: #648043;
  --danger-color-hover: #BB0000;
  --main-color-translucid: rgba(112, 144, 74, 0.4);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn {
  padding: 0.5em;
  width: 6em;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.btn:hover {
  cursor: pointer;
}

.btn-primary{
  color: white;
  background: var(--main-color);
  border-color: var(--main-color);
}
.btn-primary:hover{
  background: var(--main-color-hover);
  border-color: var(--main-color-hover);
}

.btn-danger{
  color: white;
  background: var(--danger-color);
  border-color: var(--danger-color);
}

.btn-danger:hover{
  background: var(--danger-color-hover);
  border-color: var(--danger-color-hover);
}
