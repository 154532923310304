table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.div-table-order{
    overflow-x: auto;
    margin-bottom: 3em;
}
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
td, th:hover {
    cursor: pointer;
}

tr:nth-child(even) {
    background-color: var(--main-color-translucid);
}
