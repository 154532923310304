.div-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: var(--main-color);
    width: 100vw;
    height: 100vh;
}

.div-login .form-login .div-error{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    margin-bottom: 1em;
    border-radius: .25em;
}

.div-login .auth__title{
    margin: 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

.div-login .form-login{
    box-shadow: 1px 1px 1px 2px grey;
    padding: 5em;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20em;
    margin: auto;
    background: white;

}


.div-login .form-login input{
    margin-bottom: 1em;
    padding: 0.5em;
}

.div-login .form-login .logo-login{
    margin: auto;
}

.div-login .form-login .btn-primary{
  margin: auto;
}

.divError{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    margin-bottom: 2em;
    padding: 5px;
}


@media  (max-width: 588px) {

    .div-login .form-login {
        max-width: 14em;
        margin: auto;
        padding: 2.5em;
    }

}
