.div-orders{
text-align: center;
}

.div-orders h1{
    color: var(--main-color);
}

.div-orders .next{
    margin-right: 0.5em;
}


