.div-order-detail h1,h3{
    color: var(--main-color);

}


.div-order-detail{
    text-align: center;
}

.div-order-detail button{
    width: 11em;
    margin-bottom: 2em;
}
